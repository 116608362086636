.side-line::after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background: #dfdfdf;
}
